import { IoCloseCircle } from "react-icons/io5";
import constants from "./constants";
import updateGameSession from "./updateGameSession";
import { generateRandomKey } from "./generateRandomKey";

export default function scheduleAnswer(notice, setNewGameState, messages) {
    const { title, hintText, buttonText } = messages;

    const key = generateRandomKey()

    notice.open({
        key: key,
        duration: null,
        content: (
            <div>
                <div className="d-flex justify-content-between px-2">
                    <small className="tag">Challenge</small>
                    <strong>{title}</strong>
                </div>
                <div>{hintText}</div>
                <button
                    onClick={() => {
                        updateGameSession(setNewGameState, constants.fetchGraphGameAnswerUrl);
                        notice.close(key);
                    }}
                    type="submit"
                    className="navbar-item ms-0 mt-2"
                >
                    {buttonText}
                </button>
            </div>
        ),
        closable: true,
        closeIcon: <IoCloseCircle />,
    });
}