import { useCallback } from 'react';
import changeGroup from '../helpers/changeGroup';
import fetchGraphDataForGame from '../helpers/fetchGraphDataForGame';
import filterGraph from '../helpers/filterGraph';
import focusOnNode from '../helpers/focusOnNode';
import getNeighborsGraphById from '../helpers/getNeighborsGraphById';
import { getNodeContent } from '../helpers/getNodeContent';
import highlightGraph from '../helpers/highlightGraph';
import refreshAllIds from '../helpers/refreshAllIds';
import { updateNodeInGraph } from '../helpers/updateNodeInGraph';
import { mergeGraphs } from '../helpers/mergeGraphs';
import findParents from '../helpers/findParents';

const useNodeSelection = ({
  isFetching,
  setIsFetching,
  graph,
  setGraph,
  start,
  setStart,
  alreadyLoadedNode,
  addAlreadyLoadedNode,
  nodeAlreadyLoaded,
  networkRef,
  topic,
  setNewGameState,
  setLastSelectedNode,
  setLastSelectedNodeParents,
  setShowNodeContent,
  handleNodeTimerRef,
  timeoutRefForGraphUpdate,
  setPhysicsEnabled,
  taskQueue
}) => {
  const handleNodeSelection = useCallback(
    async (event) => {
      if (handleNodeTimerRef.current) return;
      handleNodeTimerRef.current = setTimeout(() => {
        handleNodeTimerRef.current = null;
      }, 100);

      if (isFetching) return;

      const { nodes } = event;
      const selectedNode = getNodeContent(graph, nodes[0]);
      setLastSelectedNode(selectedNode);

      if (!selectedNode) return;

      const selectedNodeParents = findParents(graph, selectedNode.id);
      if (selectedNodeParents) {
        setLastSelectedNodeParents(selectedNodeParents[0]);
      }

      if (nodeAlreadyLoaded(selectedNode.id)) {
        setShowNodeContent(true);
        return;
      }

      setIsFetching(true);
      changeGroup(selectedNode.id, 'loading', networkRef);

      taskQueue.addTask(async () => {
        taskQueue.stop();

        if (timeoutRefForGraphUpdate.current) {
          clearTimeout(timeoutRefForGraphUpdate.current);
        }

        if (nodes.length > 0) {
          timeoutRefForGraphUpdate.current = setTimeout(async () => {
            setPhysicsEnabled(true);
            try {
              const neighbors = getNeighborsGraphById(selectedNode.id, graph);
              const topic_ = neighbors.nodes[0]?.title;
              const subtopic = selectedNode.title;

              if (start) {
                focusOnNode(1, networkRef, 0, 0, 1000);
              }

              const gameData = await fetchGraphDataForGame(topic_ ?? topic, subtopic, start);
              
              if (gameData.challengeFinished){
                setTimeout(()=>{
                  setShowNodeContent(true);
                }, 1250)
              } else {
                setShowNodeContent(true);
              }

              setNewGameState(gameData);

              addAlreadyLoadedNode(selectedNode.id);
              setStart(false);
              setIsFetching(false);

              let graphCopy = JSON.parse(JSON.stringify(graph));
              graphCopy = updateNodeInGraph(graphCopy, selectedNode.id, selectedNode);

              const filteredGraph = filterGraph(gameData.graph, graphCopy, true);
              const refreshedData = refreshAllIds(filteredGraph, graphCopy.nodes.length + 1, selectedNode.id);
              const mergedGraph = mergeGraphs(graphCopy, refreshedData);
              setGraph(mergedGraph);
              
              const newGroup = 
                gameData.graph.nodes[0].group ?? 
                (selectedNode.group && selectedNode.group !== 'active' ? selectedNode.group : 'selected');

              changeGroup(selectedNode.id, newGroup, networkRef);
              highlightGraph(gameData.challengePath, mergedGraph, networkRef);
            } catch (error) {
              console.error(error);
            }
          }, 100);
        }
      });
    },
    [
      isFetching,
      graph,
      topic,
      start,
      addAlreadyLoadedNode,
      nodeAlreadyLoaded,
      networkRef,
      setNewGameState,
      setLastSelectedNode,
      setLastSelectedNodeParents,
      setShowNodeContent,
      setPhysicsEnabled,
      setIsFetching,
      setStart,
      setGraph,
      handleNodeTimerRef,
      timeoutRefForGraphUpdate,
      taskQueue
    ]
  );

  return handleNodeSelection;
};

export default useNodeSelection;
