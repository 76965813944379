export default (nodeId, networkRef, xOffset = 0, yOffset = 0, duration = 500) => {
    let focusOptions = {
        scale: 0.70,
        animation: {
            duration: duration,  // Set duration to 500ms (default is 1000ms)
            easing: "easeInOutQuad"  // Use a fast easing function (or any other fast easing function)
        },
        offset: {
            x: xOffset,
            y: yOffset
        }
    }
    networkRef.current.focus(nodeId, focusOptions);
    return
};