import { useEffect } from 'react';

// Close the modal handler
export function useCloseModalListener(setShowNodeContent) {
  useEffect(() => {
    const handleCloseModal = () => setShowNodeContent(false);
    document.addEventListener('closeModal', handleCloseModal);
    return () => {
      document.removeEventListener('closeModal', handleCloseModal);
    };
  }, []);
}
