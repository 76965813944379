import constants from "./constants";

const fetchGraphDataForGame = async (topic, subtopic = '', start = false) => {
    const response = await fetch(
        `${constants.fetchGraphGameUrl}?topic=${topic}${subtopic ? '&subtopic=' + subtopic : ''}${start ? '&start=true' : ''}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: "include"
    });

    if (!response.ok) {
        throw new Error(response);
    }

    return await response.json();
}

export default fetchGraphDataForGame