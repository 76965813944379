import './style/App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import GameGraph from './components/GameGraph';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SearchPage />} />
        <Route path="/:topic" element={<GameGraph />} />
      </Routes>
    </Router>
  );
};

export default App;
