export default function getNeighborsGraphById(nodeId, graph) {
    // Find edges connected to the given node
    const connectedEdges = graph.edges.filter(
        edge => edge.from === nodeId || edge.to === nodeId
    );

    // Collect neighbor node IDs (including the given node)
    const connectedNodeIds = new Set(
        connectedEdges.flatMap(edge => [edge.from, edge.to])
    );

    // Retrieve nodes for the subgraph
    const connectedNodes = graph.nodes.filter(node =>
        connectedNodeIds.has(node.id)
    );

    // Return the subgraph
    return {
        nodes: connectedNodes,
        edges: connectedEdges
    };
}