import ReactDOM from 'react-dom'
import '../style/Modal.css'

export default function Modal({ children, title }) {
    const onClose = () => {
        document.dispatchEvent(new CustomEvent('closeModal'));
    }

    const handleBackdropClick = (e) => {
        if (e.target.classList.contains('modal-backdrop')) {
            onClose();
        }
    };

    return ReactDOM.createPortal(
        <div className="modal-backdrop" onClick={handleBackdropClick}>
            <div className="modal-content fade-in">
                <div className="d-flex justify-content-between">
                    <h1 className='navbar-item disabled mb-2 px-4 py-3 me-5'>{title}</h1>
                    <div className='align-self-end'>
                        <button className="navbar-item mb-2" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
                {children}
            </div>
        </div>,
        document.getElementById('modal-root')
    );
}