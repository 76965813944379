import React from 'react';
import '../style/TopicsGrid.css'; // Import the CSS file

const topics = [
  { name: "Software Development", emoji: "💻" },
  { name: "Psychology", emoji: "🧠" },
  { name: "Music", emoji: "🎵" },
  { name: "Cooking", emoji: "🍳" },
  { name: "Art", emoji: "🎨" },
  { name: "Math", emoji: "➕" },
  { name: "Philosophy", emoji: "🧠" },
  { name: "Literature", emoji: "📚" },
  { name: "History", emoji: "🏛️" },
  { name: "Physics", emoji: "⚛️" },
  { name: "Chemistry", emoji: "🧪" },
  { name: "Biology", emoji: "🧬" },
  { name: "Sociology", emoji: "🧑‍🤝‍🧑" },
  { name: "Anthropology", emoji: "🗿" },
  { name: "Economics", emoji: "💸" },
  { name: "Political Science", emoji: "🏛️" },
  { name: "Engineering", emoji: "⚙️" },
  { name: "Architecture", emoji: "🏗️" },
  { name: "Graphic Design", emoji: "🎨" },
  { name: "Film Studies", emoji: "🎬" },
  { name: "Theater", emoji: "🎭" },
  { name: "Dance", emoji: "💃" },
  { name: "Photography", emoji: "📸" },
  { name: "Linguistics", emoji: "🗣️" },
  { name: "Poetry", emoji: "✍️" },
  { name: "Astronomy", emoji: "🌌" },
  { name: "Geology", emoji: "🪨" },
  { name: "Environmental Science", emoji: "🌍" },
  { name: "Information Technology", emoji: "💻" },
  { name: "Computer Science", emoji: "🖥️" },
  { name: "Robotics", emoji: "🤖" }
];

const TopicsGrid = () => {
  return (
    <div className="topics-grid">
      {topics.map((topic, index) => (
        <div onClick={() => window.location.href = `/${topic.name}`} key={index} className="topic-card d-flex">
          <div>
            <div className="emoji">{topic.emoji}</div>
            <div className="name">{topic.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopicsGrid;
