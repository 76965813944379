import { IoPlay } from "react-icons/io5";
import { IoIosPause } from "react-icons/io";
import { BiHome } from "react-icons/bi";
import { LuLoader } from "react-icons/lu";
import GamePointsDisplay from "./GamePointsDisplay";

const Navbar = ({
    handlePlayClick,
    playing,
    isFetching,
    points
}) => {
    return <div className='navbar'>
        <button onClick={() => window.location.href = '/'} className='navbar-item icon'>
            {<BiHome />}
        </button>
        <button onClick={handlePlayClick} className='navbar-item icon'>
            {!playing ? <IoPlay/> : <IoIosPause/> }
        </button>
        <div className="navbar-item disabled">
            <GamePointsDisplay points={points} />
        </div>
        {isFetching ? <button onClick={handlePlayClick} className='navbar-item icon blink_me rotating disabled'>
            <LuLoader />
        </button> : null}
    </div>;
}

export default Navbar