import React from "react";
import "../style/GamePointsDisplay.css";
import useGamePoints from "../hooks/useGamePoints";

const GamePointsDisplay = ({ points }) => {
  const { state, displayTotal, addingPoints } = useGamePoints(points);

  return (
    <div className="game-points-wrapper">
      <div className={`game-points-container`}>
        <div className={"points-small"}>
          <p>Total Points: {displayTotal - 1}</p>
        </div>
      </div>
    </div>
  );
};

export default GamePointsDisplay;
