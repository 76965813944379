import { useEffect, useState } from "react";
import fitNodesOnScreen from "../helpers/fitNodesOnScreen";
import { IoCloseCircle } from "react-icons/io5";
import constants from "../helpers/constants";
import focusOnNode from "../helpers/focusOnNode";
import pulseNode from "../helpers/pulseNode";
import scheduleAnswer from "../helpers/scheduleAnswer";
import updateGameSession from "../helpers/updateGameSession";
import checkForHint from "../helpers/checkForHint";

export default function useHandleNewGameState({
    newGameState,
    setNewGameState,
    setGameState,
    networkRef,
    notice,
    setPoints,
    pulseNodeTimerRef,
    answerNotificationTimerRef,
    hintNotificationTimerRef,
    taskQueue,
}) {
    const [answerTime, setAnswerTime] = useState();

    useEffect(() => {
        if (JSON.stringify(newGameState) === "{}") return;
        
        // Challenge finished
        if (newGameState.challengeFinished) {
            
            setPoints(newGameState.points);

            if (pulseNodeTimerRef.current?.stopAnimation) {
                pulseNodeTimerRef.current.stopAnimation();
            }
            notice.destroy();
            notice.open({
                content: (
                    <div>
                        <div className="d-flex justify-content-between px-2">
                            <small className="tag">Challenge</small>
                            <strong>Congratulations!</strong>
                        </div>
                        <div>You found the answer: {newGameState.challengeFinished}!</div>
                    </div>
                ),
                duration: 10,
                closable: true,
                showProgress:false,
                closeIcon: <IoCloseCircle />,
            });
        }

        // Challenge started or updated
        if (newGameState.challenge) {
            notice.open({
                content: (
                    <div>
                        <div className="d-flex justify-content-between px-2">
                            <small className="tag">Challenge</small>
                            <strong>Hint</strong>
                        </div>
                        <div>{newGameState.challenge[1]}</div>
                    </div>
                ),
                duration: null,
                closable: true,
                closeIcon: <IoCloseCircle />,
            });

            clearTimeout(answerNotificationTimerRef.current)
            answerNotificationTimerRef.current = setTimeout(() => {
                scheduleAnswer(notice, setNewGameState, {
                    title: "Need assistance?",
                    hintText: "Click here to reveal the answer.",
                    buttonText: "Show the answer",
                });
            }, constants.answerTime * 1000)
        }

        // Challenge answer revealed
        if (newGameState.challengeAnswer) {
            const titleToId = new Map(networkRef.current.body.data.nodes.map(node => [node.title, node.id]));
            const nodeId = titleToId.get(newGameState.challengeAnswer);
            focusOnNode(nodeId, networkRef, 0, 0, 1000);
            pulseNode(nodeId, networkRef, pulseNodeTimerRef);
        }

        // Hint given
        if (newGameState.hint) {
            notice.open({
                content: (
                    <div>
                        <div className="d-flex justify-content-between px-2">
                            <small className="tag">Challenge</small>
                            <strong>Hint</strong>
                        </div>
                        <div>{newGameState.hint}</div>
                    </div>
                ),
                duration: null,
                closable: true,
                closeIcon: <IoCloseCircle />,
            });

        }

        // Update game session periodically, unless the game ended
        let updateSessionTimeout;
        if (!newGameState.end) {
            updateSessionTimeout = setTimeout(() => {
                updateGameSession(setNewGameState);
            }, 100);
        } else {
            taskQueue.start();
        }

        // Merge new state
        setGameState((prev) => ({ ...prev, ...newGameState }));

        return () => {
            if (updateSessionTimeout) clearTimeout(updateSessionTimeout);
        };
    }, [newGameState]);
}
