import { useEffect } from 'react';
import { updateEdges } from '../helpers/updateEdges';
import { updateNodes } from '../helpers/updateNodes';

// Handles graph state updates whenever the graph changes.
export function useHandleGraphUpdates(graph, networkRef) {
  useEffect(() => {
    updateNodes(graph, networkRef);
    updateEdges(graph, networkRef);
  }, [graph]);
}
