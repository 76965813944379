export default class TaskQueue {
  constructor() {
    this.tasks = [];
    this.isProcessing = false;
  }

  addTask(task) {
    this.tasks.push(task);
  }

  start(interval = 100) {
    if (this.isProcessing) return;
    this.isProcessing = true;

    this.intervalId = setInterval(async () => {
      const currentTask = this.tasks.shift();
      if (currentTask) {
        try {
          await currentTask();
        } catch (err) {
          console.error("Task execution error:", err);
        }
      }
    }, interval);
  }

  stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.isProcessing = false;
      this.intervalId = null;
    }
  }

  isQueueEmpty() {
    return this.tasks.length === 0;
  }
}
