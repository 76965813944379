import React, { useState, useRef, useEffect } from 'react';
import Graph from 'react-vis-network-graph';
import constants from '../helpers/constants';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import { useNotification } from 'rc-notification';
import NotificationContainer from './NotificationContainer';
import { createLabel } from '../helpers/createLabel';
import useGamePoints from '../hooks/useGamePoints';
import Modal from './Modal';
import NodeContent from './NodeContent';
import TaskQueue from '../components/TaskQueue';
import { useCloseModalListener } from '../hooks/useCloseModalListener';
import { useScreenResizing } from '../hooks/useScreenResizing';
import { useInitialNodeSetup } from '../hooks/useInitialNodeSetup';
import { useHandleGraphUpdates } from '../hooks/useHandleGraphUpdates';
import useHandleNewGameState from '../hooks/useHandleNewGameState';
import useNodeSelection from '../hooks/useNodeSelection';
import Chat from './Chat';

const motion = {
  motionName: 'rc-notification-fade',
  motionAppear: true,
  motionEnter: true,
  motionLeave: true,
  onLeaveStart: (ele) => {
    const { offsetHeight } = ele;
    return { height: offsetHeight };
  },
  onLeaveActive: () => ({ height: 0, opacity: 0, margin: 0 }),
};

const taskQueue = new TaskQueue();
taskQueue.start();

const GameGraph = () => {
  const timerRef = useRef();
  const networkRef = useRef();
  const timeoutRefForGraphUpdate = useRef();
  const handleNodeTimerRef = useRef();
  const pulseNodeTimerRef = useRef();
  const hintNotificationTimerRef = useRef();
  const answerNotificationTimerRef = useRef();

  const { topic } = useParams();
  const [currentTopic] = useState(topic);
  const [screenWidth, setScreenWidth] = useState(512);
  const [screenHeight, setScreenHeight] = useState(512);
  const [physicsEnabled, setPhysicsEnabled] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [start, setStart] = useState(true);
  const [gameState, setGameState] = useState({});
  const [newGameState, setNewGameState] = useState({});
  const [lastSelectedNode, setLastSelectedNode] = useState({});
  const [lastSelectedNodeParents, setLastSelectedNodeParents] = useState([]);
  const [showNodeContent, setShowNodeContent] = useState(false);
  const [alreadyLoadedNode, setAlreadyLoadedNode] = useState([]);

  const [points, setPoints] = useState({
    total: 0,
    timePoints: 1,
    noHintPoints: 0,
    noAnswerPoints: 0,
  });

  const { displayTotal, addingPoints } = useGamePoints(points);

  const [notice, contextHolder] = useNotification({
    showProgress: true,
    getContainer: () => document.getElementById('notification-container'),
    motion,
  });

  const [graph, setGraph] = useState({
    nodes: [
      {
        id: 1,
        label: createLabel(currentTopic),
        title: currentTopic,
        group: 'main'
      }
    ],
    edges: []
  });

  // const useEffect(()=>{
  //   setShowNodeContent(true);
  // },[])

  const addAlreadyLoadedNode = (nodeId) => {
    setAlreadyLoadedNode((prev) => [...prev, nodeId]);
  };

  const nodeAlreadyLoaded = (nodeId) => alreadyLoadedNode.includes(nodeId);

  useHandleNewGameState({
    newGameState,
    setNewGameState,
    setGameState,
    networkRef,
    notice,
    setPoints,
    pulseNodeTimerRef,
    answerNotificationTimerRef,
    hintNotificationTimerRef,
    taskQueue
  });

  const togglePhysics = () => {
    setPhysicsEnabled(!physicsEnabled);
  };

  useEffect(() => {
    if (networkRef.current) {
      networkRef.current.setOptions({
        physics: { enabled: physicsEnabled },
      });
    }
  }, [physicsEnabled]);

  const handleNodeSelection = useNodeSelection({
    isFetching,
    setIsFetching,
    graph,
    setGraph,
    start,
    setStart,
    alreadyLoadedNode,
    addAlreadyLoadedNode,
    nodeAlreadyLoaded,
    networkRef,
    topic,
    setNewGameState,
    setLastSelectedNode,
    setLastSelectedNodeParents,
    setShowNodeContent,
    handleNodeTimerRef,
    timeoutRefForGraphUpdate,
    setPhysicsEnabled,
    taskQueue
  });

  useHandleGraphUpdates(graph, networkRef);
  useInitialNodeSetup(networkRef, timerRef, graph, handleNodeSelection);
  useScreenResizing(setScreenWidth, setScreenHeight);
  useCloseModalListener(setShowNodeContent);

  const events = {
    select: (event) => handleNodeSelection(event),
    hoverNode: () => {
      document.body.style.cursor = 'pointer';
    },
    blurNode: () => {
      document.body.style.cursor = 'default';
    },
  };

  return (
    <div>
      <div className='absolute z-1 center-y center-x'>
        <div className={`points-big ${addingPoints ? 'popIn' : 'popOut'}`}>
          <p>Total Points: +{displayTotal - 1}</p>
        </div>
      </div>

      <Navbar
        handlePlayClick={togglePhysics}
        playing={physicsEnabled}
        isFetching={isFetching}
        points={points}
      />

      <NotificationContainer>
        {contextHolder}
      </NotificationContainer>

      <Graph
        getNetwork={(network) => { networkRef.current = network; }}
        options={{ ...constants.graph.options, ...{ height: `${screenHeight}px`, width: `${screenWidth}px` } }}
        events={events}
      />

      {showNodeContent && (
        <Modal title={`${lastSelectedNodeParents?.title ? lastSelectedNodeParents.title + ": " : ""}${lastSelectedNode.title}`}>
          <Chat
            subject={`${lastSelectedNodeParents?.title ? lastSelectedNodeParents.title + ": " : ""}${lastSelectedNode.title}`}
          />
        </Modal>
      )}
    </div>
  );
};

export default GameGraph;
