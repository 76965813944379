function refreshAllIds(data, offset = 1, origin = 1) {
    let currentId = offset; // Start IDs from the specified offset

    const refreshedData = { ...data };

    // Map to store old to new ID mappings for nodes
    const idMap = new Map();

    // Update node IDs with sequential numbers starting from the offset
    refreshedData.nodes = refreshedData.nodes.map(node => {
        const newId = currentId++;
        idMap.set(node.id, newId); // Store mapping from old ID to new ID
        return { ...node, id: newId };
    });

    // Update edge IDs and `from`/`to` references
    refreshedData.edges = refreshedData.edges.map(edge => {
        const newEdges = {
            ...edge,
            from: idMap.get(edge.from) || origin,
            to: edge.rebinded ? edge.to : idMap.get(edge.to) || origin
        }

        if (newEdges.from === newEdges.to){
            return edge
        }

        return newEdges
    });

    return refreshedData;
}

export default refreshAllIds