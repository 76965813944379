const filterGraph = (mainGraph, filterGraph, withEdgeRebinding = false) => {
    const idToTitleMap = new Map(mainGraph.nodes.map(node => [node.id, node.title]));
    const titlesToRemove = new Set(filterGraph.nodes.map(node => node.title));

    const newNodes = mainGraph.nodes.filter(node => !titlesToRemove.has(node.title));
    const newEdges = withEdgeRebinding
        ? rebindEdges(mainGraph.edges, titlesToRemove, idToTitleMap, filterGraph)
        : removeEdges(mainGraph.edges, titlesToRemove, mainGraph.nodes);

    return {
        ...mainGraph,
        nodes: newNodes,
        edges: newEdges
    };
};

const rebindEdges = (edges, titlesToRemove, idToTitleMap, filterGraph) => {
    const titleToIdMap = new Map(filterGraph.nodes.map(node => [node.title, node.id]));
    return edges.map(edge => {
        const destinationTitle = idToTitleMap.get(edge.to);
        if (titlesToRemove.has(destinationTitle)) {
            return {
                ...edge,
                to: titleToIdMap.get(destinationTitle),
                rebinded: true
            };
        }
        return edge;
    });
};

const removeEdges = (edges, titlesToRemove, nodes) => {
    const idsToRemove = new Set(
        nodes.filter(node => titlesToRemove.has(node.title)).map(node => node.id)
    );
    return edges.filter(edge => !idsToRemove.has(edge.to));
};

export default filterGraph;
