import constants from "./constants";

export default async function updateGameSession(callback, url = false) {
    const response = await fetch(
        url ? url : `${constants.fetchGraphGameUrl}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }
    )
    if (!response.ok) {
        throw new Error(response);
    }

    const parsedResponse = await response.json()

    callback(parsedResponse);

    return parsedResponse;
}