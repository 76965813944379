import { useState, useEffect, useCallback, useRef } from "react";

const useGamePoints = (points) => {
  const initialized = useRef();

  const [state, setState] = useState("default");
  const [displayTotal, setDisplayTotal] = useState(points.total);
  const [addingPoints, setAddingPoints] = useState(false);

  const calculateNextPoints = () => {
    if (points.timePoints > 0) return { category: "timePoints", value: points.timePoints };
    if (points.noHintPoints > 0) return { category: "noHintPoints", value: points.noHintPoints };
    if (points.noAnswerPoints > 0) return { category: "noAnswerPoints", value: points.noAnswerPoints };
    return null;
  };

  const processPoints = () => {
    const nextPoints = calculateNextPoints();
    if (!nextPoints) {
      setAddingPoints(false);
      setTimeout(() => setState("default"), 2000);
      return;
    }
  
    setAddingPoints(true);
    setState("amostradinho");
  
    const { category, value } = nextPoints;
  
    // Fixed duration in milliseconds for the process
    const duration = 500; // e.g., 2 seconds
    const intervalTime = 1; // Interval time in milliseconds
    const totalIntervals = Math.ceil(duration / intervalTime);
    const increment = Math.ceil(value / totalIntervals);
  
    let addedPoints = 0;
  
    const interval = setInterval(() => {
      if (addedPoints < value) {
        const step = Math.min(increment, value - addedPoints);
        addedPoints += step;
        setDisplayTotal((prev) => prev + step); // Only integers are added
      } else {
        clearInterval(interval);
        points[category] = 0; // Update the points object to deduct the processed points
        setAddingPoints(false);
        processPoints(); // Process the next category of points
        initialized.current = false;
      }
    }, intervalTime);
  
    return () => clearInterval(interval);
  };
  

  useEffect(() => {
    if (!addingPoints && !initialized.current) {
      initialized.current = true
      processPoints();
    }
  }, [points]);

  return { displayTotal, addingPoints };
};

export default useGamePoints;
