import { useEffect } from 'react';
import changeGroup from '../helpers/changeGroup';

// Handles the initial focus on node 1 and sets it as loading state.
export function useInitialNodeSetup(networkRef, timerRef, graph, handleNodeSelection) {
  useEffect(() => {
    changeGroup(1, 'loading', networkRef);

    if (!timerRef.current) {
      timerRef.current = handleNodeSelection({ nodes: [1] });
    }
  }, []);
}
