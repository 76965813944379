import React from 'react'
import '../style/NotificationContainer.css'

export default function NotificationContainer({ children }) {
    return <div id="notification-container" className='position-absolute text-end w-100 p-1'>
        {
            children
        }
    </div>
}
